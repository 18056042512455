var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "form-panel",
        {
          ref: "formPanel",
          staticClass: "form-container",
          attrs: {
            form: _vm.formParams,
            submitUrl: _vm.submitUrl,
            submitMethod: "put",
            submitBefore: _vm.submitBefore,
          },
        },
        [
          _c(
            "col2-detail",
            { attrs: { span: 24 } },
            [
              _c(
                "col2-block",
                { attrs: { title: "批量修改巡查点信息" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "修改类型",
                        prop: "updateFlag",
                        rules: [
                          {
                            required: true,
                            message: "请选择修改类型",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.updateFlagOps },
                        model: {
                          value: _vm.formParams.updateFlag,
                          callback: function ($$v) {
                            _vm.$set(_vm.formParams, "updateFlag", $$v)
                          },
                          expression: "formParams.updateFlag",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "巡查类型",
                        prop: "type",
                        rules: [
                          {
                            required: true,
                            message: "请选择巡查类型",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.type },
                        on: { change: _vm.typeChange },
                        model: {
                          value: _vm.formParams.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.formParams, "type", $$v)
                          },
                          expression: "formParams.type",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属公司",
                        prop: "regionId",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属公司",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: { placeholder: "输入公司名称" },
                            on: { onChange: _vm.onChange },
                            model: {
                              value: _vm.formParams.regionId,
                              callback: function ($$v) {
                                _vm.$set(_vm.formParams, "regionId", $$v)
                              },
                              expression: "formParams.regionId",
                            },
                          },
                          "v-select2",
                          _vm.regionParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "选择项目",
                        prop: "communityIdList",
                        rules: [
                          {
                            required: true,
                            message: "请选择项目",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("chosen-list-panel", {
                        attrs: {
                          list: _vm.formParams.communityIdList,
                          disabled: !_vm.formParams.regionId,
                          textAs: "communityName",
                        },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(
                              _vm.formParams,
                              "communityIdList",
                              $event
                            )
                          },
                          select: _vm.communitySelect,
                          change: _vm.chosenChange,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择分类" } },
                    [
                      _c("el-cascader", {
                        attrs: {
                          filterable: "",
                          options: _vm.categoryTreeData,
                          disabled: !_vm.formParams.regionId,
                        },
                        on: { change: _vm.changeInspectType },
                        model: {
                          value: _vm.inspectType,
                          callback: function ($$v) {
                            _vm.inspectType = $$v
                          },
                          expression: "inspectType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "关联巡查点",
                        prop: "pointIds",
                        rules: [
                          {
                            required: true,
                            message: "请选择关联巡查点",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("chosenListPanel", {
                        attrs: {
                          disabled: !_vm.formParams.communityIdList.length,
                          list: _vm.formParams.pointIds,
                        },
                        on: {
                          "update:list": function ($event) {
                            return _vm.$set(_vm.formParams, "pointIds", $event)
                          },
                          select: _vm.toSelectTag,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "col2-block",
                { attrs: { title: "新关联内容" } },
                [
                  _vm.formParams.updateFlag === 1
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "是否巡查",
                              prop: "pointStatus",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择是否巡查",
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("v-select", {
                              attrs: { options: _vm.inspectFlagOptions },
                              model: {
                                value: _vm.formParams.pointStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formParams, "pointStatus", $$v)
                                },
                                expression: "formParams.pointStatus",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm.formParams.pointStatus === 1
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "巡查频次",
                                    prop: "period",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择巡查频次",
                                        trigger: "blur",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("v-select", {
                                    staticClass: "right-wrapper",
                                    attrs: { options: _vm.periodFormOps },
                                    model: {
                                      value: _vm.formParams.period,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.formParams, "period", $$v)
                                      },
                                      expression: "formParams.period",
                                    },
                                  }),
                                  _c("v-input-number", {
                                    staticClass: "right-wrapper",
                                    attrs: {
                                      controls: "",
                                      "controls-position": "right",
                                      placeholder: "7",
                                      min: 0,
                                      max: 99,
                                    },
                                    model: {
                                      value: _vm.formParams.periodNum,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formParams,
                                          "periodNum",
                                          $$v
                                        )
                                      },
                                      expression: "formParams.periodNum",
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v("如：“日、3”表示每日巡查3次"),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "巡查间隔",
                                    prop: "timespan",
                                    rules: [
                                      {
                                        required: true,
                                        message: "当前选项不允许为空",
                                        trigger: "blur",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("v-input-number", {
                                    staticClass: "right-wrapper",
                                    attrs: {
                                      controls: "",
                                      "controls-position": "right",
                                      placeholder: "1",
                                      min: 1,
                                    },
                                    model: {
                                      value: _vm.formParams.timespan,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formParams,
                                          "timespan",
                                          $$v
                                        )
                                      },
                                      expression: "formParams.timespan",
                                    },
                                  }),
                                  _c("v-select", {
                                    attrs: { options: _vm.timespanUnitFormOps },
                                    model: {
                                      value: _vm.formParams.timespanUnit,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formParams,
                                          "timespanUnit",
                                          $$v
                                        )
                                      },
                                      expression: "formParams.timespanUnit",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "规则生效时间",
                                    prop: "effectStartTime",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择规则生效时间",
                                        trigger: "change",
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c("v-datepicker", {
                                    attrs: {
                                      type: "date",
                                      disabledDays: _vm.disabledDays,
                                    },
                                    model: {
                                      value: _vm.formParams.effectStartTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formParams,
                                          "effectStartTime",
                                          $$v
                                        )
                                      },
                                      expression: "formParams.effectStartTime",
                                    },
                                  }),
                                  _c("div", [
                                    _vm._v(
                                      "巡检规则将在该周期内有效,项目巡查将自动生成巡查计划。"
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ]
                    : [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-left": "60px" },
                            attrs: {
                              label: "是否允许相册上传图片",
                              prop: "allowAlbumUpload",
                              rules: [{ required: true }],
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "content-wrapper" },
                              [
                                _c("el-switch", {
                                  attrs: {
                                    "active-value": 1,
                                    "inactive-value": 0,
                                    "active-color": "#409EFF",
                                  },
                                  model: {
                                    value: _vm.formParams.allowAlbumUpload,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formParams,
                                        "allowAlbumUpload",
                                        $$v
                                      )
                                    },
                                    expression: "formParams.allowAlbumUpload",
                                  },
                                }),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "是否允许执行任务跳过扫描二维码",
                                      prop: "skipScan",
                                    },
                                  },
                                  [
                                    _c("el-switch", {
                                      attrs: {
                                        "active-value": 1,
                                        "inactive-value": 0,
                                        "active-color": "#409EFF",
                                      },
                                      model: {
                                        value: _vm.formParams.skipScan,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formParams,
                                            "skipScan",
                                            $$v
                                          )
                                        },
                                        expression: "formParams.skipScan",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("multi-select", {
        attrs: {
          title: "项目列表",
          isShow: _vm.showCommunityMultiSelect,
          searchUrl: _vm.queryCommunityListURL,
          searchParams: _vm.form,
          headers: _vm.communityHeaders,
          responseParams: _vm.communityResponseParams1,
          responseKey: _vm.communityResponseKey1,
          backFill: _vm.formParams.communityIdList,
          extraParams: _vm.communityExtraParams,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.showCommunityMultiSelect = $event
          },
          "update:is-show": function ($event) {
            _vm.showCommunityMultiSelect = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.formParams, "communityIdList", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.formParams, "communityIdList", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "项目名称" },
                  model: {
                    value: _vm.form.communityName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "communityName", $$v)
                    },
                    expression: "form.communityName",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("multi-select", {
        ref: "multiPoint",
        attrs: {
          title: "巡查点列表",
          isShow: _vm.isShow,
          searchUrl: _vm.getPointListURL,
          headers: _vm.headers,
          searchParams: _vm.searchParams,
          responseParams: _vm.communityResponseParams,
          responseKey: _vm.communityResponseKey,
          extraParams: _vm.extraParams,
          backFill: _vm.formParams.pointIds,
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          "update:backFill": function ($event) {
            return _vm.$set(_vm.formParams, "pointIds", $event)
          },
          "update:back-fill": function ($event) {
            return _vm.$set(_vm.formParams, "pointIds", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "巡查地点" },
                  model: {
                    value: _vm.searchParams.site,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "site", $$v)
                    },
                    expression: "searchParams.site",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "select-wrapper" },
                  [
                    _c("div", { staticClass: "text" }, [_vm._v("选择分类")]),
                    _c("el-cascader", {
                      attrs: {
                        filterable: "",
                        disabled: !!_vm.inspectType.length,
                        options: _vm.categoryTreeData,
                      },
                      on: { change: _vm.changeInspectPanelType },
                      model: {
                        value: _vm.inspectPanelType,
                        callback: function ($$v) {
                          _vm.inspectPanelType = $$v
                        },
                        expression: "inspectPanelType",
                      },
                    }),
                  ],
                  1
                ),
                _c("v-select", {
                  attrs: { label: "巡查方式", options: _vm.patternOps },
                  model: {
                    value: _vm.searchParams.pattern,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "pattern", $$v)
                    },
                    expression: "searchParams.pattern",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "状态", options: _vm.statusOps },
                  model: {
                    value: _vm.searchParams.pointStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "pointStatus", $$v)
                    },
                    expression: "searchParams.pointStatus",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "负责人" },
                  model: {
                    value: _vm.searchParams.patrolUserName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "patrolUserName", $$v)
                    },
                    expression: "searchParams.patrolUserName",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }