<!--
 * @Description: 巡查管理--巡查点管理--批量修改
 -->
<template>
  <div class="wrapper">
    <form-panel
        class="form-container"
        ref="formPanel"
        :form="formParams"
        :submitUrl="submitUrl"
        submitMethod="put"
        :submitBefore="submitBefore"
    >
      <col2-detail :span='24'>
        <col2-block title="批量修改巡查点信息">
          <el-form-item label="修改类型" prop="updateFlag" :rules="[{ required: true, message: '请选择修改类型', trigger: 'blur'}]">
            <v-select v-model="formParams.updateFlag" :options="updateFlagOps"></v-select>
          </el-form-item>
          <el-form-item label="巡查类型" prop="type" :rules="[{ required: true, message: '请选择巡查类型', trigger: 'blur'}]">
            <v-select v-model="formParams.type" :options="type" @change="typeChange"></v-select>
          </el-form-item>
          <el-form-item label="所属公司" prop="regionId" :rules="[{ required: true, message: '请选择所属公司', trigger: 'change' }]">
            <v-select2 v-model="formParams.regionId" placeholder="输入公司名称" v-bind="regionParams" @onChange="onChange"/>
          </el-form-item>
          <el-form-item label="选择项目" prop="communityIdList" :rules="[{ required: true, message: '请选择项目', trigger: 'change' }]">
            <chosen-list-panel :list.sync="formParams.communityIdList" :disabled="!formParams.regionId" textAs="communityName" @select="communitySelect" @change="chosenChange"/>
          </el-form-item>
          <el-form-item label="选择分类">
            <el-cascader
                filterable
                v-model="inspectType"
                :options="categoryTreeData"
                :disabled="!formParams.regionId"
                @change="changeInspectType"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="关联巡查点" prop="pointIds" :rules="[{ required: true, message: '请选择关联巡查点', trigger: 'change'}]">
            <chosenListPanel :disabled="!formParams.communityIdList.length" :list.sync="formParams.pointIds" @select="toSelectTag"></chosenListPanel>
          </el-form-item>
          <!--<el-form-item v-else label="关联房号" prop="houseIds" :rules="[{ required: true, message: '请选择关联房号', trigger: 'change'}]">-->
          <!--  <chosenListPanel :list.sync="formParams.houseIds" @select="toSelectRoomTag"></chosenListPanel>-->
          <!--</el-form-item>-->
        </col2-block>
        <col2-block title="新关联内容">
          <template v-if="formParams.updateFlag === 1">
            <el-form-item label="是否巡查" prop="pointStatus" :rules="[{ required: true, message: '请选择是否巡查', trigger: 'blur'}]">
              <v-select v-model="formParams.pointStatus" :options="inspectFlagOptions"></v-select>
            </el-form-item>
            <template v-if="formParams.pointStatus === 1">
              <el-form-item label="巡查频次" prop="period" :rules="[{ required: true, message: '请选择巡查频次', trigger: 'blur'}]">
                <v-select
                    class="right-wrapper"
                    v-model="formParams.period"
                    :options="periodFormOps"
                ></v-select>
                <v-input-number
                    class="right-wrapper"
                    controls
                    v-model="formParams.periodNum"
                    controls-position="right"
                    placeholder="7"
                    :min="0"
                    :max="99"
                ></v-input-number>
                <span>如：“日、3”表示每日巡查3次</span>
              </el-form-item>
              <el-form-item label="巡查间隔" prop="timespan"
                            :rules="[{ required: true, message: '当前选项不允许为空', trigger: 'blur'}]">
                <v-input-number
                    controls
                    v-model="formParams.timespan"
                    class="right-wrapper"
                    controls-position="right"
                    placeholder="1"
                    :min="1"
                ></v-input-number>
                <v-select
                    v-model="formParams.timespanUnit"
                    :options="timespanUnitFormOps"
                ></v-select>
              </el-form-item>

              <el-form-item label="规则生效时间" prop="effectStartTime"
                            :rules="[{ required: true, message: '请选择规则生效时间', trigger: 'change'}]">
                <v-datepicker
                    type="date"
                    v-model="formParams.effectStartTime"
                    :disabledDays="disabledDays"
                ></v-datepicker>
                <div>巡检规则将在该周期内有效,项目巡查将自动生成巡查计划。</div>
              </el-form-item>
            </template>
          </template>
          <template v-else>
             <el-form-item style="margin-left:60px;" label="是否允许相册上传图片" prop="allowAlbumUpload" :rules="[{ required: true }]">
              <div class="content-wrapper">
                <el-switch v-model="formParams.allowAlbumUpload" :active-value="1" :inactive-value="0" active-color="#409EFF"/>
                <el-form-item label="是否允许执行任务跳过扫描二维码" prop="skipScan">
                  <el-switch v-model="formParams.skipScan" :active-value="1" :inactive-value="0" active-color="#409EFF"/>
                </el-form-item>
              </div>
            </el-form-item>
          </template>
        </col2-block>
      </col2-detail>
    </form-panel>
    <multi-select
      title="项目列表"
      :isShow.sync="showCommunityMultiSelect"
      :searchUrl="queryCommunityListURL"
      :searchParams="form"
      :headers="communityHeaders"
      :responseParams="communityResponseParams1"
      :responseKey="communityResponseKey1"
      :backFill.sync="formParams.communityIdList"
      :extraParams="communityExtraParams"
       >
      <template #searchSlot>
        <v-input label="项目名称" v-model="form.communityName" />
      </template>
    </multi-select>
    <multi-select
        title="巡查点列表"
        :isShow.sync="isShow"
        :searchUrl="getPointListURL"
        :headers="headers"
        :searchParams="searchParams"
        :responseParams="communityResponseParams"
        :responseKey="communityResponseKey"
        :extraParams="extraParams"
        :backFill.sync="formParams.pointIds"
        ref="multiPoint"
    >
      <template #searchSlot>
        <v-input label="巡查地点" v-model="searchParams.site"></v-input>
        <div class="select-wrapper">
          <div class="text">选择分类</div>
          <el-cascader
              v-model="inspectPanelType"
              filterable
              :disabled="!!inspectType.length"
              :options="categoryTreeData"
              @change="changeInspectPanelType"
          ></el-cascader>
        </div>
        <v-select
            label="巡查方式"
            v-model="searchParams.pattern"
            :options="patternOps"
        ></v-select>
        <v-select
            label="状态"
            v-model="searchParams.pointStatus"
            :options="statusOps"
        ></v-select>
        <v-input label="负责人" v-model="searchParams.patrolUserName"></v-input>
      </template>
    </multi-select>
    <!--<multi-select-->
    <!--    title="房号列表"-->
    <!--    :isShow.sync="showRoom"-->
    <!--    :searchUrl="houseMultiSelectURL"-->
    <!--    :headers="roomHeaders"-->
    <!--    :searchParams="roomSearchParams"-->
    <!--    :responseParams="responseParams"-->
    <!--    :responseKey="responseKey"-->
    <!--    :extra-params="roomExtraParams"-->
    <!--    :backFill.sync="formParams.houseIds"-->
    <!--&gt;-->
    <!--  <template #searchSlot>-->
    <!--    <v-input label="苑" v-model="roomSearchParams.blockName" placeholder="请输入苑"></v-input>-->
    <!--    <v-input label="幢" v-model="roomSearchParams.buildingNumber" placeholder="请输入幢"></v-input>-->
    <!--    <v-input label="单元" v-model="roomSearchParams.unit" placeholder="请输入单元"></v-input>-->
    <!--    <v-input label="室" v-model="roomSearchParams.room" placeholder="请输入室"></v-input>-->
    <!--  </template>-->
    <!--</multi-select>-->
  </div>
</template>

<script>
import { Col2Block, Col2Detail, ChosenListPanel, MultiSelect } from '@/components/bussiness'
import {
  type,
  inspectFlagOptions,
  periodFormOps,
  timespanUnitFormOps,
  patternOps,
  statusOps,
  typeMap,
  patternMap,
  statusMap,
  communityStatusMap,
  updateFlagOps
} from './map'
import { normalCommunityParams,regionParams } from 'common/select2Params'
import {
  getInspectCategoryAllURL,
  getPointListURL,
  houseMultiSelectURL,
  batchUpdatePointInspectInfoUrl,
  getCategoryDetailURL, getCategoryIdUrl,queryCommunityListURL
} from './api'

export default {
  name: '',
  components: {
    Col2Block,
    Col2Detail,
    ChosenListPanel,
    MultiSelect
  },
  data() {
    return {
      formParams: {
        pointStatus: 1,
        period: 1,// 巡查频次
        periodNum: 1,
        timespan: 1,// 巡查间隔
        timespanUnit: 1,
        effectStartTime: '',// 规则生效时间
        pointIds: [],// 关联巡查点
        houseIds: [],// 关联房号
        type: 1,
        communityId: '',
        communityIdList: [],
        regionId: '',
        allowAlbumUpload: 1,
        skipScan: 0,
        updateFlag: 1
      },
      submitUrl: batchUpdatePointInspectInfoUrl,
      type,
      normalCommunityParams,
      regionParams,
      categoryTreeData: [],// 巡查分类
      isShow: false,
      getPointListURL,
      headers: [
        {
          prop: 'site',
          label: '巡查地点'
        },
        {
          prop: 'typeStr',
          label: '巡查类型',
          formatter(row) {
            return typeMap[row.type]
          }
        },
        {
          prop: 'parentName',
          label: '一级分类'
        },
        {
          prop: 'categoryName',
          label: '二级分类'
        },
        {
          prop: 'patternStr',
          label: '巡查方式',
          formatter (row) {
            return patternMap[row.pattern]
          }
        },
        {
          prop: 'patrolUserName',
          label: '负责人'
        },
        {
          prop: 'pointStatusStr',
          label: '状态',
          formatter (row) {
            return statusMap[row.pointStatus]
          }
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
      ],
      roomHeaders: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'unitName',
          label: '单元信息'
        },
        {
          prop: 'address',
          label: '苑'
        },
        {
          prop: 'buildNum',
          label: '幢'
        },
        {
          prop: 'floor',
          label: '楼层'
        },
        {
          prop: 'unit',
          label: '单元'
        },
        {
          prop: 'room',
          label: '室'
        }
      ],
      inspectFlagOptions,
      periodFormOps,
      timespanUnitFormOps,
      searchParams: {
        site: '',
        pattern: undefined,
        firstLevel: null,
        secondLevel: null,
        pointStatus: undefined,
        patrolUserName: ''
      },
      // 房号
      roomSearchParams: {
        blockName: '',
        buildingNumber: '',
        unit: '',
        room: '',
      },
      communityResponseParams: {
        id: 'id',
        name: 'site'
      },
      communityResponseKey: {
        id: 'id',
        name: 'text'
      },
      responseParams: {
        id: 'id',
        name: 'unitName'
      },
      responseKey: {
        id: 'id',
        name: 'text'
      },
      patternOps,
      inspectType: [], // 选择分类
      inspectPanelType: [], // 关联巡查点分类
      statusOps,
      showRoom: false,
      houseMultiSelectURL,
      subjectOps: [],
      //项目
      showCommunityMultiSelect: false,
      queryCommunityListURL,
      communityHeaders: [
        {
          prop: 'communityName',
          label: '项目名称'
        },
        {
          prop: 'communityStatusText',
          label: '项目状态',
          formatter: row => this.communityStatusMap[row.communityStatus]
        },
        {
          prop: 'communityStage',
          label: '项目阶段'
        },
        {
          prop: 'province',
          label: '所在省'
        },
        {
          prop: 'city',
          label: '所在市'
        },
        {
          prop: 'area',
          label: '所在区'
        },

        {
          prop: 'regionName',
          label: '分子公司'
        }
      ],
      communityResponseParams1: {
        id: 'communityId',
        name: 'communityName'
      },
      communityResponseKey1: {
        id: 'id',
        name: 'communityName'
      },
      form: {
        communityName: ''
      },
      updateFlagOps
    }
  },
  created() {
    this.communityStatusMap = {...communityStatusMap, undefined: '全部'}
    // this.getInspectCategory()
  },
  mounted() {
  },
  watch: {},
  computed: {
    extraParams() {
      return {
        type: this.formParams.type,
        communityIdList: this.formParams.communityIdList.map((item)=>{return item.id}).toString()
      }
    },
    roomExtraParams() {
      return {
        communityId: this.formParams.communityId,
      }
    },
    communityExtraParams () {
      return {
        regionId: this.formParams.regionId,
        communityStatus: 0
      }
    }
  },

  methods: {
    communitySelect () {
      this.showCommunityMultiSelect = true
    },
    chosenChange (newValue) {
      if(!newValue || newValue.length === 0){
        if(this.formParams.pointIds.length){
          this.formParams.pointIds = []
        }
        return
      }
    },
    typeChange() {
      this.getInspectCategory()
    },
    onChange() {
      //公司改变
      this.form.communityName = ''
      this.formParams.communityIdList = []
      this.getInspectCategory()
    },
    getInspectCategory() {

      this.categoryTreeData = []

      this.inspectType = []
      this.inspectPanelType = []
      this.searchParams.firstLevel = ''
      this.searchParams.secondLevel = ''
      if(this.formParams.pointIds.length){
        this.formParams.pointIds = []
      }
      this.formParams.houseIds = []      

      this.$axios.get(getInspectCategoryAllURL, {
        params: {
          type: this.formParams.type,
          //communityId: this.formParams.communityId,
          regionId: this.formParams.regionId
        }
      }).then(res => {
        if(res.data.length > 0) {
          this.categoryTreeData = this.setChilrdren(res.data)
        }else {
          this.categoryTreeData = []
        }
      })
    },
    setChilrdren(data) {
      const result = []
      const map = {}
      for (const item of data) {
        map[item.categoryId] = {
          ...item,
          value: item.categoryId,
          label: item.name,
        }
      }
      for (const item of data) {
        const tempItem = map[item.categoryId]
        if (tempItem.parentId === 0) {
          result.push(tempItem)
        } else if (map[item.parentId]) {
          if (map[item.parentId].children) {
            map[item.parentId].children.push(tempItem)
          } else {
            map[item.parentId].children = [tempItem]
          }
        }
      }
      return result
    },
    toSelectTag() {
      this.isShow = true
      this.$refs.multiPoint.searchData()
    },
    toSelectRoomTag() {
      this.showRoom = true
    },
    disabledDays(date) {
      return new Date(date).getTime() < Date.now() - 24 * 60 * 60 * 1000
    },
    changeInspectType(val) {
      console.log(val)
      //this.formParams.pointIds = []
      this.formParams.houseIds = []
      if(this.formParams.pointIds.length){
        this.formParams.pointIds = []
      }
      if(val.length) {
        this.inspectPanelType = [val[0],val[1]]
        this.searchParams.firstLevel = val[0]
        this.searchParams.secondLevel = val[1]
        
      } else {
        this.inspectPanelType = []
        this.searchParams.firstLevel = ''
        this.searchParams.secondLevel = ''
      }
      

      // 项目巡查 获取默认巡查点
      // if(this.formParams.type === 1) {
      //   this.$axios.get(getCategoryIdUrl + `/${val[1]}`, {
      //   }).then(res => {
      //     this.formParams.pointIds = [{
      //       id: res.data.id,
      //       text: res.data.name,
      //     }]
      //   })
      // }

      // 获取巡查点
      // this.$axios.get(getCategoryIdUrl + `/${val[1]}`, {
      // }).then(res => {
      //   this.formParams.pointIds = [{
      //     id: res.data.id,
      //     text: res.data.name,
      //   }]
      // })


      this.$axios.get(getCategoryDetailURL + `/${val[1]}`, {
      }).then(res => {
        for (const formKey in this.formParams) {
          const target = res.data[formKey]
          if(target || target === 0) {
            this.formParams[formKey] = target
          }
        }
      })
    },
    changeInspectPanelType(val) {
      console.log(val)
      this.searchParams.firstLevel = val[0]
      this.searchParams.secondLevel = val[1]
    },
    submitBefore(data) {
      // 项目巡查
      // if(data.type === 1) {
      //   data.pointIds = data.pointIds.map(item => item.id)
      //   Reflect.deleteProperty(data, 'houseIds')
      // }else {
      //   data.houseIds = data.houseIds.map(item => item.id)
      //   Reflect.deleteProperty(data, 'pointIds')
      // }
      data.pointIds = data.pointIds.map(item => item.id)
      Reflect.deleteProperty(data, 'houseIds')


      const deleteList = ['communityId','type','regionId','communityIdList']
      console.log('删除参数')
      deleteList.forEach(item => {
        Reflect.deleteProperty(data, item)
      })
      return true
    }
  }
}
</script>

<style scoped lang="scss">
.right-wrapper {
  margin-right: 30px;
}

.select-wrapper {
  display: flex;
  align-items: center;

  .text {
    margin-right: 5px;
    width: 90px;
    text-align: right;
  }
}
.f-left-subject{
  ::v-deep .el-input__suffix {
    background-color: #333333;
    background: url('./images/search.png');
    background-repeat: no-repeat;
    background-size: 17px 17px;
    background-position: center;
  }
}
::v-deep .el-form-item__label{
  width: 177px !important;
}

.content-wrapper {
  display: flex;
  align-items: center; 
  ::v-deep .el-form-item{
    margin-left: 50px;
  }
  ::v-deep .el-form-item__label{
    width: 240px !important;
  }
  ::v-deep .el-form-item__content{
    margin-left: 240px !important;
  }
}
</style>
